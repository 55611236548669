/** Colours
 *  ------------------------------------------------------------------------------------------------
 *  Accessibility checks:

    Light colours originals - mostly AAA some AA with dark text
    https://abc.useallfive.com/?colors[]=FFFFFF,FCC3EE,A68CEF,66B9FF,7CC993,FFBF77,FF7A7B,222222

    Light colours all AAA with dark text
    https://abc.useallfive.com/?colors[]=FFFFFF,FCC3EE,B8A0FC,66B9FF,7CC993,FFBF77,FF8C8D,222222,414141,666666

    Dark colours AA with light text
    https://abc.useallfive.com/?colors[]=FFFFFF,8B6482,7A66B2,4177A5,4C7D5A,986933,B95151,222222

    Dark colours AAA with light text
    https://abc.useallfive.com/?colors[]=222222,724768,5B4C87,2E577A,385C43,71512D,8E3F40,FFFFFF,DEDEDE,A2A2A2
**/

:root {
  /** Dark colours
   *  ----------------------------------------------------------------------------------------------
   *  defined here so that we do not have to change them in two places below...
  **/

  /* original */
  /* --colour-dark-primary: #ffffff;
  --colour-dark-invert: #333333;
  --colour-dark-pink: #8b1370;
  --colour-dark-blue: #12508f;
  --colour-dark-purple: #451e7a;
  --colour-dark-teal: #134670;
  --colour-dark-green: #07521d;
  --colour-dark-yellow: #793f00;
  --colour-dark-red: #740808;
  --colour-dark-subtle: #dedede;
  --colour-dark-subtler: #a2a2a2;
  --colour-dark-background-subtle: #393939; */

  /* vibrant */
  /* --colour-dark-primary: #ffffff;
  --colour-dark-invert: #333333;
  --colour-dark-pink: #773b77;
  --colour-dark-blue: #00213e;
  --colour-dark-purple: #2c0778;
  --colour-dark-teal: #114a5e;
  --colour-dark-green: #053f17;
  --colour-dark-yellow: #6f5500;
  --colour-dark-red: #4e0a02;
  --colour-dark-subtle: #dedede;
  --colour-dark-subtler: #a2a2a2;
  --colour-dark-background-subtle: #393939; */

  /* pastel */
  --colour-dark-primary: #ffffff;
  --colour-dark-invert: #333333;
  --colour-dark-pink: #773b77;
  --colour-dark-blue: #00213e;
  --colour-dark-purple: #2c0778;
  --colour-dark-teal: #114a5e;
  --colour-dark-green: #053f17;
  --colour-dark-yellow: #5f3d00;
  --colour-dark-red: #4e0a02;
  --colour-dark-subtle: #dedede;
  --colour-dark-subtler: #a2a2a2;
  --colour-dark-background-subtle: #393939;

  /** Light colours
   *  ----------------------------------------------------------------------------------------------
  **/
  /* original */
  /* --colour-light-primary: #333333;
  --colour-light-invert: #ffffff;
  --colour-light-pink: #fcc3ef;
  --colour-light-blue: #a4cbf2;
  --colour-light-purple: #be92fa;
  --colour-light-teal: #77bdf8;
  --colour-light-green: #7cc993;
  --colour-light-yellow: #ffb561;
  --colour-light-red: #ff7777;
  --colour-light-subtle: #414141;
  --colour-light-subtler: #666666;
  --colour-light-background-subtle: #fafdfd; */

  /* vibrant */
  /* --colour-light-primary: #333333;
  --colour-light-invert: #ffffff;
  --colour-light-pink: #ffbaff;
  --colour-light-blue: #0088ff;
  --colour-light-purple: #ac86f7;
  --colour-light-teal: #38cdff;
  --colour-light-green: #0ebf44;
  --colour-light-yellow: #ffea26;
  --colour-light-red: #fd4b4a;
  --colour-light-subtle: #414141;
  --colour-light-subtler: #666666;
  --colour-light-background-subtle: #fafdfd; */

  /* pastel */
  --colour-light-primary: #333333;
  --colour-light-invert: #ffffff;
  --colour-light-pink: #ffdaff;
  --colour-light-blue: #82c5ff;
  --colour-light-purple: #d3c1f7;
  --colour-light-teal: #c1f0ff;
  --colour-light-green: #8ad1a0;
  --colour-light-yellow: #fff274;
  --colour-light-red: #ff9493;
  --colour-light-subtle: #414141;
  --colour-light-subtler: #666666;
  --colour-light-background-subtle: #fafdfd;

  /** alternate text colours - used only on light mode with default (white) background
   *  ----------------------------------------------------------------------------------------------
  **/
  /* original and vibrant */
  /* --colour-light-default-pink: #c92ea5;
  --colour-light-default-blue: #346ca3;
  --colour-light-default-purple: #763ac8;
  --colour-light-default-teal: #1673c2;
  --colour-light-default-green: #228640;
  --colour-light-default-yellow: #be5400;
  --colour-light-default-red: #e31f1f; */

  /* pastel */
  --colour-light-default-pink: #c92ea5;
  --colour-light-default-blue: #1673c2;
  --colour-light-default-purple: #763ac8;
  --colour-light-default-teal: #346ca3;
  --colour-light-default-green: #228640;
  --colour-light-default-yellow: #8f6f00;
  --colour-light-default-red: #e31f1f;


  /** light mode primary and named colours
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-primary: var(--colour-light-primary);
  --colour-invert: var(--colour-light-invert);
  --colour-subtle: var(--colour-light-subtle);
  --colour-subtler: var(--colour-light-subtler);
  --colour-secondary: var(--colour-light-subtle);
  --colour-highlight: var(--colour-light-subtler);
  --colour-background-body: var(--colour-light-invert);
  --colour-background-subtle: var(--colour-light-background-subtle);


  /** light mode cms set background colours
   *  ----------------------------------------------------------------------------------------------
   *  light mode has the lighter colours in the background
  **/
  --colour-background-black-white: var(--colour-light-invert);
  --colour-background-pink: var(--colour-light-pink);
  --colour-background-purple: var(--colour-light-purple);
  --colour-background-teal: var(--colour-light-teal);
  --colour-background-blue: var(--colour-light-blue);
  --colour-background-green: var(--colour-light-green);
  --colour-background-yellow: var(--colour-light-yellow);
  --colour-background-red: var(--colour-light-red);

  /** light mode cms set foreground colours
   *  ----------------------------------------------------------------------------------------------
   *  light mode uses the dark colours for text to contrast the light backgrounds
  **/
  --colour-foreground-black-white: var(--colour-light-primary);
  --colour-foreground-pink: var(--colour-dark-pink);
  --colour-foreground-purple: var(--colour-dark-purple);
  --colour-foreground-teal: var(--colour-dark-teal);
  --colour-foreground-blue: var(--colour-dark-blue);
  --colour-foreground-green: var(--colour-dark-green);
  --colour-foreground-yellow: var(--colour-dark-yellow);
  --colour-foreground-red: var(--colour-dark-red);

  /** light mode font colours against default (white) backgrounds
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-default-foreground-black-white: var(--colour-light-primary);
  --colour-default-foreground-pink: var(--colour-light-default-pink);
  --colour-default-foreground-purple: var(--colour-light-default-purple);
  --colour-default-foreground-teal: var(--colour-light-default-teal);
  --colour-default-foreground-blue: var(--colour-light-default-blue);
  --colour-default-foreground-green: var(--colour-light-default-green);
  --colour-default-foreground-yellow: var(--colour-light-default-yellow);
  --colour-default-foreground-red: var(--colour-light-default-red);
}


/** Dark modes
 *  ------------------------------------------------------------------------------------------------
 *  whether toggled or set by browser prefs
 *  ! - postcss can't convert these to hex values. Effectively dark mode only works in browsers
 *  with css variable support
 *  ref: https://github.com/postcss/postcss-custom-properties/issues/223
**/

@supports (--css: variables) {

  :root.dark-mode {
    /** dark mode primary and named colours
    *  ----------------------------------------------------------------------------------------------
    **/
    --colour-primary: var(--colour-dark-primary);
    --colour-invert: var(--colour-dark-invert);
    --colour-subtle: var(--colour-dark-subtle);
    --colour-subtler: var(--colour-dark-subtler);
    --colour-secondary: var(--colour-dark-subtle);
    --colour-highlight: var(--colour-dark-subtler);
    --colour-background-body: var(--colour-dark-invert);
    --colour-background-subtle: var(--colour-dark-background-subtle);


    /** dark mode cms set background colours
    *  ----------------------------------------------------------------------------------------------
    *  dark mode has the darker colours in the background
    **/
    --colour-background-black-white: var(--colour-dark-invert);
    --colour-background-pink: var(--colour-dark-pink);
    --colour-background-purple: var(--colour-dark-purple);
    --colour-background-teal: var(--colour-dark-teal);
    --colour-background-blue: var(--colour-dark-blue);
    --colour-background-green: var(--colour-dark-green);
    --colour-background-yellow: var(--colour-dark-yellow);
    --colour-background-red: var(--colour-dark-red);

    /** dark mode cms set foreground colours
    *  ----------------------------------------------------------------------------------------------
    *  dark mode uses the light colours for text to contrast the dark backgrounds
    **/
    --colour-foreground-black-white: var(--colour-dark-primary);
    --colour-foreground-pink: var(--colour-light-pink);
    --colour-foreground-purple: var(--colour-light-purple);
    --colour-foreground-teal: var(--colour-light-teal);
    --colour-foreground-blue: var(--colour-light-blue);
    --colour-foreground-green: var(--colour-light-green);
    --colour-foreground-yellow: var(--colour-light-yellow);
    --colour-foreground-red: var(--colour-light-red);

    /** dark mode font colours against default (black) backgrounds
    *  ----------------------------------------------------------------------------------------------
    **/
    --colour-default-foreground-black-white: var(--colour-dark-primary);
    --colour-default-foreground-pink: var(--colour-light-pink);
    --colour-default-foreground-purple: var(--colour-light-purple);
    --colour-default-foreground-teal: var(--colour-light-teal);
    --colour-default-foreground-blue: var(--colour-light-blue);
    --colour-default-foreground-green: var(--colour-light-green);
    --colour-default-foreground-yellow: var(--colour-light-yellow);
    --colour-default-foreground-red: var(--colour-light-red);
  }
}

/** IF WE WANT DARK MODE AUTOMATICALLY FOR DEVICES THAT PREFER IT
 *  ------------------------------------------------------------------------------------------------
 *  we need the additional .light-mode version to overwrite the system defaults
**/

/*
@supports (--css: variables) {

  :root.light-mode {
    --colour-primary: var(--colour-light-primary);
    --colour-invert: var(--colour-light-invert);
    --colour-subtle: var(--colour-light-subtle);
    --colour-subtler: var(--colour-light-subtler);
    --colour-secondary: var(--colour-light-subtle);
    --colour-highlight: var(--colour-light-subtler);
    --colour-background-body: var(--colour-light-invert);
    --colour-background-subtle: var(--colour-light-background-subtle);
    --colour-background-black-white: var(--colour-light-invert);
    --colour-background-pink: var(--colour-light-pink);
    --colour-background-purple: var(--colour-light-purple);
    --colour-background-teal: var(--colour-light-teal);
    --colour-background-blue: var(--colour-light-blue);
    --colour-background-green: var(--colour-light-green);
    --colour-background-yellow: var(--colour-light-yellow);
    --colour-background-red: var(--colour-light-red);
    --colour-foreground-black-white: var(--colour-light-primary);
    --colour-foreground-pink: var(--colour-dark-pink);
    --colour-foreground-purple: var(--colour-dark-purple);
    --colour-foreground-teal: var(--colour-dark-teal);
    --colour-foreground-blue: var(--colour-dark-blue);
    --colour-foreground-green: var(--colour-dark-green);
    --colour-foreground-yellow: var(--colour-dark-yellow);
    --colour-foreground-red: var(--colour-dark-red);
    --colour-default-foreground-black-white: var(--colour-light-primary);
    --colour-default-foreground-pink: var(--colour-light-default-pink);
    --colour-default-foreground-purple: var(--colour-light-default-purple);
    --colour-default-foreground-teal: var(--colour-light-default-teal);
    --colour-default-foreground-blue: var(--colour-light-default-blue);
    --colour-default-foreground-green: var(--colour-light-default-green);
    --colour-default-foreground-yellow: var(--colour-light-default-yellow);
    --colour-default-foreground-red: var(--colour-light-default-red);
  }
}

@media (prefers-color-scheme: dark) {

  @supports (--css: variables) {

    :root {
      --colour-primary: var(--colour-dark-primary);
      --colour-invert: var(--colour-dark-invert);
      --colour-subtle: var(--colour-dark-subtle);
      --colour-subtler: var(--colour-dark-subtler);
      --colour-secondary: var(--colour-dark-subtle);
      --colour-highlight: var(--colour-dark-subtler);
      --colour-background-body: var(--colour-dark-invert);
      --colour-background-subtle: var(--colour-dark-background-subtle);
      --colour-background-black-white: var(--colour-dark-invert);
      --colour-background-pink: var(--colour-dark-pink);
      --colour-background-purple: var(--colour-dark-purple);
      --colour-background-teal: var(--colour-dark-teal);
      --colour-background-blue: var(--colour-dark-blue);
      --colour-background-green: var(--colour-dark-green);
      --colour-background-yellow: var(--colour-dark-yellow);
      --colour-background-red: var(--colour-dark-red);
      --colour-foreground-black-white: var(--colour-dark-primary);
      --colour-foreground-pink: var(--colour-light-pink);
      --colour-foreground-purple: var(--colour-light-purple);
      --colour-foreground-teal: var(--colour-light-teal);
      --colour-foreground-blue: var(--colour-light-blue);
      --colour-foreground-green: var(--colour-light-green);
      --colour-foreground-yellow: var(--colour-light-yellow);
      --colour-foreground-red: var(--colour-light-red);
      --colour-default-foreground-black-white: var(--colour-dark-primary);
      --colour-default-foreground-pink: var(--colour-light-pink);
      --colour-default-foreground-purple: var(--colour-light-purple);
      --colour-default-foreground-teal: var(--colour-light-teal);
      --colour-default-foreground-blue: var(--colour-light-blue);
      --colour-default-foreground-green: var(--colour-light-green);
      --colour-default-foreground-yellow: var(--colour-light-yellow);
      --colour-default-foreground-red: var(--colour-light-red);
    }
  }
} */
