/** linking css colour variables to class names
 *  ------------------------------------------------------------------------------------------------
 *  colours can be chosen via the CMS and can appear:
 *    as background colours (in which case we use the alternate light/dark version as font colour)
 *    as foreground colours (in which case we use the default background colour white/black)
 *  dark mode uses dark colours as backgrounds and light as font colours
 *  light mode uses the dark colours for typography and light for backgrounds
**/


/** currentColor
 *  ------------------------------------------------------------------------------------------------
 *  used to handle all of this with currentColor
 *  but Safari in particular has major issues transitioning it...
 *  we're now being more explicit about colours here at the expense of heavier css...
**/

.use-colour--foreground,
.use-colour--background,
.use-background--foreground,
.use-background--background {
  transition: all var(--time-transition) var(--ease-default);
  transition-property: color, background-color, border-color;
}

.body--with-default-background.colour--default {
  color: var(--colour-primary);
}

.colour--black-white,
.colour--homesplash {
  color: var(--colour-foreground-black-white);
}

.body--with-default-background .colour--black-white,
.body--with-default-background.colour--black-white,
.body--with-default-background .colour--homesplash,
.body--with-default-background.colour--homesplash {
  color: var(--colour-default-foreground-black-white);

  & .use-colour--foreground {
    color: var(--colour-default-foreground-black-white);
  }
}

.colour--red {
  color: var(--colour-foreground-red);
}

.body--with-default-background .colour--red,
.body--with-default-background.colour--red {
  color: var(--colour-default-foreground-red);

  & .use-colour--foreground {
    color: var(--colour-default-foreground-red);
  }
}

.colour--yellow {
  color: var(--colour-foreground-yellow);
}

.body--with-default-background .colour--yellow,
.body--with-default-background.colour--yellow {
  color: var(--colour-default-foreground-yellow);

  & .use-colour--foreground {
    color: var(--colour-default-foreground-yellow);
  }
}

.colour--green {
  color: var(--colour-foreground-green);
}

.body--with-default-background .colour--green,
.body--with-default-background.colour--green {
  color: var(--colour-default-foreground-green);

  & .use-colour--foreground {
    color: var(--colour-default-foreground-green);
  }
}

.colour--teal {
  color: var(--colour-foreground-teal);
}

.body--with-default-background .colour--teal,
.body--with-default-background.colour--teal {
  color: var(--colour-default-foreground-teal);

  & .use-colour--foreground {
    color: var(--colour-default-foreground-teal);
  }
}

.colour--blue {
  color: var(--colour-foreground-blue);
}

.body--with-default-background .colour--blue,
.body--with-default-background.colour--blue {
  color: var(--colour-default-foreground-blue);

  & .use-colour--foreground {
    color: var(--colour-default-foreground-blue);
  }
}

.colour--pink {
  color: var(--colour-foreground-pink);
}

.body--with-default-background .colour--pink,
.body--with-default-background.colour--pink {
  color: var(--colour-default-foreground-pink);

  & .use-colour--foreground {
    color: var(--colour-default-foreground-pink);
  }
}

.colour--purple {
  color: var(--colour-foreground-purple);
}

.body--with-default-background .colour--purple,
.body--with-default-background.colour--purple {
  color: var(--colour-default-foreground-purple);

  & .use-colour--foreground {
    color: var(--colour-default-foreground-purple);
  }
}

/** background colours against default backgrounds
 *  ------------------------------------------------------------------------------------------------
**/
.use-colour--background {
  color: var(--colour-background-body);
}

/** colours as background colour
 *  ------------------------------------------------------------------------------------------------
 *  when the body has this class colours are used as background colours
 *  we need to apply the foreground version of the colour to typographical elements
**/
.body--with-colour-background {

  & .colour--black-white,
  &.body-surrogate.colour--black-white,
  & .colour--homesplash,
  &.body-surrogate.colour--homesplash {
    color: var(--colour-background-black-white);
    background: var(--colour-background-black-white);

    & .use-colour--foreground {
      color: var(--colour-foreground-black-white);
    }

    & .use-colour--background {
      color: var(--colour-background-black-white);
    }

    & .use-background--background {
      background: var(--colour-background-black-white);
      border-color: var(--colour-background-black-white);
    }

    & .use-background--foreground {
      background: var(--colour-foreground-black-white);
      border-color: var(--colour-foreground-black-white);
    }
  }

  & .colour--red,
  &.body-surrogate.colour--red {
    color: var(--colour-background-red);


    &.body-surrogate {
      background: var(--colour-background-red);
    }

    & .use-colour--foreground {
      color: var(--colour-foreground-red);
    }

    & .use-colour--background {
      color: var(--colour-background-red);
    }

    & .use-background--background {
      background: var(--colour-background-red);
      border-color: var(--colour-background-red);
    }

    & .use-background--foreground {
      background: var(--colour-foreground-red);
      border-color: var(--colour-foreground-red);
    }
  }

  & .colour--yellow,
  &.body-surrogate.colour--yellow {
    color: var(--colour-background-yellow);


    &.body-surrogate {
      background: var(--colour-background-yellow);
    }

    & .use-colour--foreground {
      color: var(--colour-foreground-yellow);
    }

    & .use-colour--background {
      color: var(--colour-background-yellow);
    }

    & .use-background--background {
      background: var(--colour-background-yellow);
      border-color: var(--colour-background-yellow);
    }

    & .use-background--foreground {
      background: var(--colour-foreground-yellow);
      border-color: var(--colour-foreground-yellow);
    }
  }

  & .colour--green,
  &.body-surrogate.colour--green {
    color: var(--colour-background-green);


    &.body-surrogate {
      background: var(--colour-background-green);
    }

    & .use-colour--foreground {
      color: var(--colour-foreground-green);
    }

    & .use-colour--background {
      color: var(--colour-background-green);
    }

    & .use-background--background {
      background: var(--colour-background-green);
      border-color: var(--colour-background-green);
    }

    & .use-background--foreground {
      background: var(--colour-foreground-green);
      border-color: var(--colour-foreground-green);
    }
  }

  & .colour--teal,
  &.body-surrogate.colour--teal {
    color: var(--colour-background-teal);


    &.body-surrogate {
      background: var(--colour-background-teal);
    }

    & .use-colour--foreground {
      color: var(--colour-foreground-teal);
    }

    & .use-colour--background {
      color: var(--colour-background-teal);
    }

    & .use-background--background {
      background: var(--colour-background-teal);
      border-color: var(--colour-background-teal);
    }

    & .use-background--foreground {
      background: var(--colour-foreground-teal);
      border-color: var(--colour-foreground-teal);
    }
  }

  & .colour--blue,
  &.body-surrogate.colour--blue {
    color: var(--colour-background-blue);


    &.body-surrogate {
      background: var(--colour-background-blue);
    }

    & .use-colour--foreground {
      color: var(--colour-foreground-blue);
    }

    & .use-colour--background {
      color: var(--colour-background-blue);
    }

    & .use-background--background {
      background: var(--colour-background-blue);
      border-color: var(--colour-background-blue);
    }

    & .use-background--foreground {
      background: var(--colour-foreground-blue);
      border-color: var(--colour-foreground-blue);
    }
  }

  & .colour--pink,
  &.body-surrogate.colour--pink {
    color: var(--colour-background-pink);


    &.body-surrogate {
      background: var(--colour-background-pink);
    }

    & .use-colour--foreground {
      color: var(--colour-foreground-pink);
    }

    & .use-colour--background {
      color: var(--colour-background-pink);
    }

    & .use-background--background {
      background: var(--colour-background-pink);
      border-color: var(--colour-background-pink);
    }

    & .use-background--foreground {
      background: var(--colour-foreground-pink);
      border-color: var(--colour-foreground-pink);
    }
  }

  & .colour--purple,
  &.body-surrogate.colour--purple {
    color: var(--colour-background-purple);


    &.body-surrogate {
      background: var(--colour-background-purple);
    }

    & .use-colour--foreground {
      color: var(--colour-foreground-purple);
    }

    & .use-colour--background {
      color: var(--colour-background-purple);
    }

    & .use-background--background {
      background: var(--colour-background-purple);
      border-color: var(--colour-background-purple);
    }

    & .use-background--foreground {
      background: var(--colour-foreground-purple);
      border-color: var(--colour-foreground-purple);
    }
  }
}
